import loadable from '@loadable/component'
import React from 'react'

import { insuranceDetailPath, insurancePlanPath, medicalPath } from '../constants/route-name'
import {
  claimDetailHistoryPath,
  claimDetailPath,
  claimPath,
  claimWelfareHistoryPath,
  claimWelfarePath,
  companyWelfarePath,
  confirmOtpPath,
  homePath,
  loginPath
} from '../constants/route-name'

const HomePage = loadable(() => import('../pages/home/HomePage'))
const ClaimPage = loadable(() => import('../pages/claim/ClaimPage'))
const ClaimDetailPage = loadable(() => import('../pages/claim/ClaimDetailPage'))
const ClaimDetailHistoryPage = loadable(() => import('../pages/claim/ClaimDetailHistoryPage'))
const CompanyWelfarePage = loadable(() => import('../pages/company-welfare/CompanyWelfarePage'))
const ClaimWelfarePage = loadable(() => import('../pages/company-welfare/ClaimWelfarePage'))
const ClaimWelfareHistoryPage = loadable(() => import('../pages/company-welfare/ClaimWelfareHistoryPage'))
const InsuranceDetailPage = loadable(() => import('../pages/insurance-plan/InsuranceDetailPage'))
const InsurancePlanPage = loadable(() => import('../pages/insurance-plan/InsurancePlanPage'))
const MedicalPage = loadable(() => import('../pages/medical/MedicalPage'))

const EmployeeConfirmOTPPage = loadable(() => import('../pages/employee/EmployeeConfirmOTPPage'))
const EmployeeLoginPage = loadable(() => import('../pages/employee/EmployeeLoginPage'))

export const RENDER_ROUTES = [
  { path: loginPath, component: <EmployeeLoginPage /> },
  { path: confirmOtpPath, component: <EmployeeConfirmOTPPage /> }
]

export const RENDER_PROTECTED_ROUTES = [
  { path: homePath, component: <HomePage />, isIndex: true, withoutLink: false, haveEClaim: false },
  { path: claimPath, component: <ClaimPage />, withoutLink: true, haveEClaim: true },
  {
    path: claimDetailPath(':claimId', ':categoryName'),
    component: <ClaimDetailPage />,
    withoutLink: true,
    haveEClaim: true
  },
  {
    path: claimDetailHistoryPath(':historyId'),
    component: <ClaimDetailHistoryPage />,
    withoutLink: true,
    haveEClaim: true
  },
  {
    path: companyWelfarePath,
    component: <CompanyWelfarePage />,
    withoutLink: false,
    haveFlexBen: true
  },
  {
    path: claimWelfarePath(':welfareId', ':categoryName'),
    component: <ClaimWelfarePage />,
    withoutLink: false,
    haveFlexBen: true
  },
  {
    path: claimWelfareHistoryPath(':historyId'),
    component: <ClaimWelfareHistoryPage />,
    withoutLink: false,
    haveFlexBen: true
  },
  {
    path: insurancePlanPath(':yearId'),
    component: <InsurancePlanPage />,
    withoutLink: false,
    haveFlexBen: false
  },
  {
    path: insuranceDetailPath(':yearId'),
    component: <InsuranceDetailPage />,
    withoutLink: false,
    haveFlexBen: false
  },
  {
    path: medicalPath,
    component: <MedicalPage />,
    withoutLink: false,
    haveFlexBen: false
  }
]
