// Route Path for navigate

// Home
export const homePath = '/home'

// Company Welfare
export const companyWelfarePath = `${homePath}/company-welfare`
export const claimWelfarePath = (welfareId: string, categoryName: string) =>
  `${homePath}/company-welfare/${categoryName}/${welfareId}`
export const claimWelfareHistoryPath = (historyId: string) => `${homePath}/company-welfare/${historyId}`

// Claim
export const claimPath = `${homePath}/claim`
export const claimDetailPath = (claimId: string, categoryName: string) => `${homePath}/claim/${categoryName}/${claimId}`
export const claimDetailHistoryPath = (historyId: string) => `${homePath}/claim/${historyId}`

// Auth
export const loginPath = '/'
export const confirmOtpPath = '/confirm-otp'

export const onboardingPath = '/onboarding'

// Insurance plan
export const insurancePlanPath = (yearId: string) => `${homePath}/insurance-plan/${yearId}`
export const insuranceDetailPath = (yearId: string) => `${homePath}/insurance-detail/${yearId}`

// Medical
export const medicalPath = `${homePath}/medical`
