import { createAsyncThunk } from '@reduxjs/toolkit'

import { MEDICAL_URLS } from '../../../configs/api'
import { AxiosService } from '../../../services/api'
import { createThunkName } from '../../../utils/createThunkname'
import {
  GetCompareMedicalResultQueryParams,
  GetCompareMedicalResultResponse,
  GetMedicalEmployeeInfoResponse,
  GetMedicalFilteringYearMasterDataResponse,
  GetMedicalResultResponse,
  GetSummaryResultResponse
} from './types'

const PREFIX = 'medical'

export const MedicalService = {
  getEmployeeInfoAsyncThunk: createAsyncThunk(createThunkName(PREFIX, 'getEmployeeInfo'), async (yearId: string) => {
    try {
      const result = await AxiosService.get<GetMedicalEmployeeInfoResponse>(MEDICAL_URLS.getMedicalEmployeeInfo(yearId))
      return result.data
    } catch (error) {
      throw error
    }
  }),
  getSummaryResultAsyncThunk: createAsyncThunk(createThunkName(PREFIX, 'getSummaryResult'), async () => {
    try {
      const result = await AxiosService.get<GetSummaryResultResponse>(MEDICAL_URLS.getMedicalSummaryResult)
      return result.data
    } catch (error) {
      throw error
    }
  }),
  getMedicalResultAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getMedicalResultByEmployeeId'),
    async (yearId: string) => {
      try {
        const result = await AxiosService.get<GetMedicalResultResponse>(MEDICAL_URLS.getMedicalResult(yearId))
        return result.data
      } catch (error) {
        throw error
      }
    }
  ),
  getCompareMedicalResultAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getCompareMedicalResult'),
    async (queryParams: GetCompareMedicalResultQueryParams) => {
      try {
        const result = await AxiosService.get<GetCompareMedicalResultResponse>(MEDICAL_URLS.getCompareMedicalResult, {
          params: queryParams
        })
        return result.data
      } catch (error) {
        throw error
      }
    }
  ),
  getMedicalFilteringYearMasterDataAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getMedicalFilteringYearMasterData'),
    async () => {
      try {
        const result = await AxiosService.get<GetMedicalFilteringYearMasterDataResponse>(
          MEDICAL_URLS.masterData.getMedicalFilteringYearMasterData
        )
        return result.data.yearList
      } catch (error) {
        throw error
      }
    }
  )
}
