import React, { useEffect, useState } from 'react'
import { Toaster } from 'react-hot-toast'
import { useSelector } from 'react-redux'
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router'

import { RENDER_PROTECTED_ROUTES, RENDER_ROUTES } from './configs/routes'
import { homePath, loginPath, onboardingPath } from './constants/route-name'
import { AuthService } from './features/auth/services/AuthService'
import { resetClaimState } from './features/claim/stores/slice'
import { resetCompanyWelfareState } from './features/company-welfare/stores/slice'
import { resetHomeState } from './features/home/stores/slice'
import { resetMedicalState } from './features/medical/stores/slice'
import EmployeeLayout from './layout/EmployeeLayout'
import HomeLayout from './layout/HomeLayout'
import OnboardingPage from './pages/OnboardingPage'
import { currentUserSelector } from './stores/current-user/selector'
import { useAppDispatch } from './stores/store'
import { fontSize } from './themes/themes'

const toastOptions = {
  style: {
    fontSize: fontSize.h6,
    fontWeight: 500,
    borderRadius: '50px'
  }
}

function App() {
  const [isLoading, setIsLoading] = useState(false)

  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const location = useLocation()
  const currentUser = useSelector(currentUserSelector)

  const token = localStorage.getItem('token')
  const notFoundElement = currentUser?.fullName ? (
    <Navigate to={homePath} />
  ) : (
    <Navigate to={loginPath} state={{ prevPath: location.pathname }} />
  )

  const onFetchUser = async () => {
    if (token) {
      try {
        setIsLoading(true)
        await dispatch(AuthService.getCurrentEmployeeAsyncThunk()).unwrap()
        if (location.state?.prevPath) {
          navigate(location.state.prevPath, { replace: true })
        }
      } catch (error) {
        localStorage.setItem('token', '')
        navigate(loginPath, { replace: true })
      }
    }
    setIsLoading(false)
  }

  useEffect(() => {
    onFetchUser()
    dispatch(resetHomeState())
    dispatch(resetClaimState())
    dispatch(resetCompanyWelfareState())
    dispatch(resetMedicalState())
  }, [location.pathname])

  if (isLoading)
    return (
      <Routes>
        <Route path='*' element={<OnboardingPage />} />
      </Routes>
    )

  return (
    <>
      <Toaster toastOptions={toastOptions} />
      <Routes>
        {!currentUser ? (
          <Route path='/' element={<EmployeeLayout />}>
            {RENDER_ROUTES.map((route) => (
              <Route key={route.path} path={route.path} element={route.component} />
            ))}
          </Route>
        ) : (
          <Route path='/home' element={<HomeLayout />}>
            {RENDER_PROTECTED_ROUTES.map((route) => {
              // Have claimUrl and route must not have link
              const haveClaimUrlCondition = Boolean(currentUser.claimUrl) && route.withoutLink
              // Don't have EClaim features
              const dontHaveEClaimRouteCondition = !currentUser.haveEClaim && route.haveEClaim
              const dontHaveFlexbenRouteCondition = !currentUser.haveFlexBen && route.haveFlexBen
              if (haveClaimUrlCondition || dontHaveEClaimRouteCondition || dontHaveFlexbenRouteCondition) return
              return (
                <Route
                  key={route.path}
                  path={route.isIndex ? undefined : route.path}
                  index={route.isIndex}
                  element={route.component}
                />
              )
            })}
          </Route>
        )}
        <Route path='*' element={notFoundElement} />
        <Route path={onboardingPath} element={<OnboardingPage />} />
      </Routes>
    </>
  )
}

export default App
