import { createAsyncThunk } from '@reduxjs/toolkit'

import { COMPANY_WELFARE_URLS } from '../../../configs/api'
import { AxiosService } from '../../../services/api'
import { createThunkName } from '../../../utils/createThunkname'
import {
  CancelClaimWelfareRequestDto,
  CreateRequestWelfareDto,
  GetAllBenefitByCategoryResponse,
  GetBenefitDetailByIdResponse,
  GetHistoryUsedPointQueryParams,
  GetHistoryUsedPointResponse,
  GetRequestWelfareByHistoryIdResponse,
  UpdateRequestWelfareDetailByIdDto
} from './types'

const PREFIX = 'company-welfare'

export const CompanyWelfareService = {
  getAllBenefitByCategoryAsyncThunk: createAsyncThunk(createThunkName(PREFIX, 'getAllBenefitByCategory'), async () => {
    try {
      const result = await AxiosService.get<GetAllBenefitByCategoryResponse>(
        COMPANY_WELFARE_URLS.getAllBenefitByCategory
      )
      return result.data.benefitCategories
    } catch (error) {
      throw error
    }
  }),
  getHistoryUsedPointAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getHistoryUsedPoint'),
    async (queryParams: GetHistoryUsedPointQueryParams) => {
      try {
        const result = await AxiosService.get<GetHistoryUsedPointResponse>(COMPANY_WELFARE_URLS.getHistoryUsedPoint, {
          params: queryParams
        })
        return result.data
      } catch (error) {
        throw error
      }
    }
  ),
  getBenefitDetailByIdAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getBenefitDetailById'),
    async (benefitId: string) => {
      try {
        const result = await AxiosService.get<GetBenefitDetailByIdResponse>(
          COMPANY_WELFARE_URLS.getBenefitDetailById(benefitId)
        )
        return result.data
      } catch (error) {
        throw error
      }
    }
  ),
  getRequestWelfareByHistoryIdAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getRequestWelfareByHistoryId'),
    async (historyId: string) => {
      try {
        const result = await AxiosService.get<GetRequestWelfareByHistoryIdResponse>(
          COMPANY_WELFARE_URLS.getRequestWelfareByHistoryId(historyId)
        )
        return result.data
      } catch (error) {
        throw error
      }
    }
  ),
  createRequestWelfareAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'createRequestWelfare'),
    async (dto: CreateRequestWelfareDto) => {
      try {
        await AxiosService.post(COMPANY_WELFARE_URLS.createRequestWelfare, dto)
      } catch (error) {
        throw error
      }
    }
  ),
  updateRequestWelfareDetailByIdAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'updateRequestWelfareDetailById'),
    async (dto: UpdateRequestWelfareDetailByIdDto) => {
      try {
        await AxiosService.put(COMPANY_WELFARE_URLS.updateRequestWelfareDetailById, dto)
      } catch (error) {
        throw error
      }
    }
  ),
  cancelClaimWelfareRequestAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'cancelClaimWelfareRequest'),
    async (dto: CancelClaimWelfareRequestDto) => {
      try {
        await AxiosService.post(COMPANY_WELFARE_URLS.cancelClaimWelfareRequest, dto)
      } catch (error) {
        throw error
      }
    }
  )
}
