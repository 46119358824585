import { createAsyncThunk } from '@reduxjs/toolkit'

import { AUTHENTICATION_URLS } from '../../../configs/api'
import { AxiosService } from '../../../services/api'
import { createThunkName } from '../../../utils/createThunkname'

const PREFIX = 'auth'

export const AuthService = {
  requestOTPAsyncThunk: createAsyncThunk(createThunkName(PREFIX, 'requestOtp'), async (dto: RequestOtpDTO) => {
    try {
      const result = await AxiosService.post<RequestOtpResponse>(AUTHENTICATION_URLS.requestOtp, dto)
      return result.data.otp
    } catch (error) {
      throw error
    }
  }),
  confirmOTPAsyncThunk: createAsyncThunk(createThunkName(PREFIX, 'confirmOtp'), async (dto: ConfirmOtpDTO) => {
    try {
      const result = await AxiosService.post<ConfirmOtpResponse>(AUTHENTICATION_URLS.confirmOtp, dto)
      localStorage.setItem('token', result.data.token)
      return result.data.token
    } catch (error) {
      throw error
    }
  }),
  resendOTPAsyncThunk: createAsyncThunk(createThunkName(PREFIX, 'resendOtp'), async (dto: ResendOtpDto) => {
    try {
      const result = await AxiosService.post<ResendOtpResponse>(AUTHENTICATION_URLS.resendOtp, dto)
      return result.data.otp
    } catch (error) {
      throw error
    }
  }),
  getCustomerInfoAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getCustomerInfo'),
    async (customerId: string) => {
      try {
        const result = await AxiosService.get<GetCustomerInfoResponse>(AUTHENTICATION_URLS.getCustomerInfo(customerId))
        return result.data
      } catch (error) {
        throw error
      }
    }
  ),
  getCurrentEmployeeAsyncThunk: createAsyncThunk(createThunkName(PREFIX, 'getCurrentEmployee'), async () => {
    try {
      const result = await AxiosService.get<GetCurrentEmployeeResponse>(AUTHENTICATION_URLS.getCurrentEmployee)
      return result.data.employee
    } catch (error) {
      localStorage.setItem('token', '')
      return undefined
    }
  }),
  logout: () => {
    localStorage.setItem('token', '')
  }
}
