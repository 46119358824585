import { createSlice } from '@reduxjs/toolkit'

import { MasterData } from '../../../types/types'
import { transformMasterData } from '../../../utils/transformMasterData'
import { MedicalService } from '../services/MedicalService'
import { CompareMedicalCategory, MedicalState } from './type'

const initialState: MedicalState = {
  //State
  isGetEmployeeInfoLoading: false,
  isGetMedicalResultLoading: false,
  isGetSummaryResultLoading: false,
  isGetCompareMedicalResultLoading: false,
  isGetMedicalFilteringYearMasterDataLoading: false,
  medicalAttributesMasterData: [],
  compareMedicalResultList: [],
  listOfCompareMedicalAttributeKeyByCategory: [],

  //Master Data
  fetchedMedicalFilteringYearMasterData: []
}

const slice = createSlice({
  name: 'medical',
  initialState,
  reducers: {
    resetMedicalState: () => initialState,
    resetCompareMedicalResultList: (state) => {
      state.compareMedicalResultList = []
      state.medicalAttributesMasterData = []
      state.listOfCompareMedicalAttributeKeyByCategory = []
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(MedicalService.getEmployeeInfoAsyncThunk.pending, (state) => {
        state.isGetEmployeeInfoLoading = true
      })
      .addCase(MedicalService.getEmployeeInfoAsyncThunk.rejected, (state) => {
        state.isGetEmployeeInfoLoading = false
      })
      .addCase(MedicalService.getEmployeeInfoAsyncThunk.fulfilled, (state, action) => {
        state.isGetEmployeeInfoLoading = false
        state.fetchedEmployeeInfo = action.payload
      })
      .addCase(MedicalService.getMedicalResultAsyncThunk.pending, (state) => {
        state.isGetMedicalResultLoading = true
      })
      .addCase(MedicalService.getMedicalResultAsyncThunk.rejected, (state) => {
        state.isGetMedicalResultLoading = false
      })
      .addCase(MedicalService.getMedicalResultAsyncThunk.fulfilled, (state, action) => {
        state.isGetMedicalResultLoading = false
        state.fetchedMedicalResult = action.payload
      })
      .addCase(MedicalService.getSummaryResultAsyncThunk.pending, (state) => {
        state.isGetSummaryResultLoading = true
      })
      .addCase(MedicalService.getSummaryResultAsyncThunk.rejected, (state) => {
        state.isGetSummaryResultLoading = false
      })
      .addCase(MedicalService.getSummaryResultAsyncThunk.fulfilled, (state, action) => {
        state.isGetSummaryResultLoading = false
        state.fetchedSummaryResult = action.payload
      })
      .addCase(MedicalService.getMedicalFilteringYearMasterDataAsyncThunk.pending, (state) => {
        state.isGetMedicalFilteringYearMasterDataLoading = true
      })
      .addCase(MedicalService.getMedicalFilteringYearMasterDataAsyncThunk.rejected, (state) => {
        state.isGetMedicalFilteringYearMasterDataLoading = false
      })
      .addCase(MedicalService.getMedicalFilteringYearMasterDataAsyncThunk.fulfilled, (state, action) => {
        state.isGetMedicalFilteringYearMasterDataLoading = false
        state.fetchedMedicalFilteringYearMasterData = transformMasterData(action.payload)
      })
      .addCase(MedicalService.getCompareMedicalResultAsyncThunk.pending, (state) => {
        state.isGetCompareMedicalResultLoading = true
      })
      .addCase(MedicalService.getCompareMedicalResultAsyncThunk.rejected, (state) => {
        state.isGetCompareMedicalResultLoading = false
      })
      .addCase(MedicalService.getCompareMedicalResultAsyncThunk.fulfilled, (state, action) => {
        const listOfCompareMedicalAttributeKeyByCategory = [] as CompareMedicalCategory[]
        const listOfUsedMedAttributes = [] as MasterData[]
        // Pushing new category of medical attribute to a list w/o duplicated category
        action.payload.medicalResultList.forEach((res) =>
          res.resultList.forEach((r) =>
            listOfCompareMedicalAttributeKeyByCategory.filter((res) => res.categoryId === r.categoryId).length < 1
              ? listOfCompareMedicalAttributeKeyByCategory.push({
                  categoryName: r.categoryName,
                  categoryId: r.categoryId,
                  attributeIds: new Set<string>()
                })
              : null
          )
        )
        // Pushing medical attributes to listOfCompareMedicalAttributeKeyByCategory
        listOfCompareMedicalAttributeKeyByCategory.forEach((item) =>
          action.payload.medicalResultList.forEach((res) => {
            res.resultList.forEach(
              (r) =>
                r.categoryId === item.categoryId &&
                r.medicalDetailList.map((med) => {
                  item.attributeIds.add(med.id)
                  if (listOfUsedMedAttributes.filter((attr) => attr.id === med.id).length < 1)
                    listOfUsedMedAttributes.push({ id: med.id, name: med.name })
                })
            )
          })
        )

        state.medicalAttributesMasterData = listOfUsedMedAttributes
        state.isGetCompareMedicalResultLoading = false
        state.listOfCompareMedicalAttributeKeyByCategory = listOfCompareMedicalAttributeKeyByCategory
        state.compareMedicalResultList = action.payload.medicalResultList
      })
  }
})

export const { resetMedicalState, resetCompareMedicalResultList } = slice.actions
export default slice.reducer
