import React from 'react'

import { COLORS_THEME } from '../../../constants/colors'
import { IconProps } from '../../../types/types'

const PointIcon = ({ size = 30 }: IconProps) => {
  return (
    <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} xmlns='http://www.w3.org/2000/svg'>
      <g stroke={COLORS_THEME.primary} strokeWidth='2.75' fill='none' fillRule='evenodd' strokeLinecap='round'>
        <path
          transform={`scale(${size / 30})`}
          d='M2 15a13 13 0 1 1 25.999.002A13 13 0 0 1 2 15z'
          strokeLinejoin='round'
        />
        <path
          transform={`scale(${size / 30})`}
          d='M19 12.5c-.135-2.057-1.84-3.616-3.819-3.493H12v6.986h3.181c1.98.123 3.684-1.436 3.819-3.493z'
          strokeLinejoin='round'
        />
        <path transform={`scale(${size / 30})`} d='M12 15.993v5.106' />
      </g>
    </svg>
  )
}

export default PointIcon
