import { Box, styled } from '@mui/material'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Outlet } from 'react-router'

import Navbar from '../components/project/navigations/Navbar'
import { COLORS } from '../constants/colors'
import { useAppDispatch } from '../stores/store'
import { isScrollTopSelector } from '../stores/visible/selector'
import { setIsScrollTop } from '../stores/visible/slice'
import Footer from './Footer'

const HomeLayout = () => {
  const dispatch = useAppDispatch()
  const isScrollTop = useSelector(isScrollTopSelector)

  const handleScroll = () => {
    const position = document.getElementById('body')!.scrollTop!
    if (position > 1 && isScrollTop) {
      dispatch(setIsScrollTop(false))
    } else if (position < 1) {
      dispatch(setIsScrollTop(true))
    }
  }
  useEffect(() => {
    document.getElementById('body')!.addEventListener('scroll', handleScroll, { passive: true })
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])
  return (
    <OuterContainer id='body'>
      <Navbar />
      <InnerContainer>
        <Outlet />
      </InnerContainer>
      <Footer />
    </OuterContainer>
  )
}

const InnerContainer = styled(Box)(() => ({}))

const OuterContainer = styled(Box)(() => ({
  height: '100vh',
  width: '100vw',
  backgroundColor: COLORS.white,
  overflowY: 'scroll'
}))

export default HomeLayout
