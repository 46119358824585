import { createAsyncThunk } from '@reduxjs/toolkit'

import { GENERAL_URLS, HOME_URLS } from '../../../configs/api'
import { AxiosService } from '../../../services/api'
import { createThunkName } from '../../../utils/createThunkname'
import {
  AcceptMedicalConsentDto,
  AcceptPDPADto,
  ChildrenChoosePlanDto,
  CoupleChoosePlanDto,
  EmployeeChoosePlanDto,
  GetBuzzybeeUrlResponse,
  GetChildrenInfoResponse,
  GetCoupleInfoResponse,
  GetEmployeeInfoResponse,
  GetEmployeeInsuranceDetailResponse,
  GetEmployeePointHistoryResponse,
  GetEnrollmentSelectionDateResponse,
  GetInsuranceDetailResponse,
  GetInsuranceForCoupleAndChildrenResponse,
  GetYearMasterDataResponse,
  UploadEmployeeImageDto
} from './types'

const PREFIX = 'home'

export const HomeService = {
  employeeChoosePlanAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'employeeChoosePlan'),
    async (employeeChoosePlanDto: EmployeeChoosePlanDto) => {
      try {
        await AxiosService.post(HOME_URLS.employeeChoosePlan, employeeChoosePlanDto)
      } catch (error) {
        throw error
      }
    }
  ),
  getEmployeeInfoAsyncThunk: createAsyncThunk(createThunkName(PREFIX, 'getEmployeeInfo'), async (yearId: string) => {
    try {
      const result = await AxiosService.get<GetEmployeeInfoResponse>(HOME_URLS.getEmployeeInfo(yearId))
      return result.data.employee
    } catch (error) {
      throw error
    }
  }),
  getEmployeeInsuranceDetailAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getEmployeeInsuranceDetail'),
    async (yearId: string) => {
      try {
        const result = await AxiosService.get<GetEmployeeInsuranceDetailResponse>(
          HOME_URLS.getEmployeeInsuranceDetail(yearId)
        )
        return result.data.insurances
      } catch (error) {
        throw error
      }
    }
  ),
  getCoupleInfoAsyncThunk: createAsyncThunk(createThunkName(PREFIX, 'getCoupleInfo'), async (yearId: string) => {
    try {
      const result = await AxiosService.get<GetCoupleInfoResponse>(HOME_URLS.getCoupleInfo(yearId))
      return result.data.couple
    } catch (error) {
      throw error
    }
  }),
  coupleChoosePlanAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'coupleChoosePlan'),
    async (coupleChoosePlanDto: CoupleChoosePlanDto) => {
      try {
        await AxiosService.post(HOME_URLS.coupleChoosePlan, coupleChoosePlanDto)
      } catch (error) {
        throw error
      }
    }
  ),
  getChildrenInfoAsyncThunk: createAsyncThunk(createThunkName(PREFIX, 'getChildrenInfo'), async (yearId: string) => {
    try {
      const result = await AxiosService.get<GetChildrenInfoResponse>(HOME_URLS.getChildrenInfo(yearId))
      return result.data.children
    } catch (error) {
      throw error
    }
  }),
  childrenChoosePlanAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'childrenChoosePlan'),
    async (childrenChoosePlanDto: ChildrenChoosePlanDto) => {
      await AxiosService.post(HOME_URLS.childrenChoosePlan, childrenChoosePlanDto)
      try {
      } catch (error) {
        throw error
      }
    }
  ),
  getInsuranceForCoupleAndChildrenAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getInsuranceForCoupleAndChildren'),
    async (yearId: string) => {
      try {
        const result = await AxiosService.get<GetInsuranceForCoupleAndChildrenResponse>(
          HOME_URLS.getInsuranceForCoupleAndChildren(yearId)
        )
        return result.data.insurances
      } catch (error) {
        throw error
      }
    }
  ),
  getEnrollmentSelectionDateAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getEnrollmentSelectionDate'),
    async (yearId: string) => {
      try {
        const result = await AxiosService.get<GetEnrollmentSelectionDateResponse>(
          HOME_URLS.getEnrollmentSelectionDate(yearId)
        )
        return result.data.enrollment
      } catch (error) {
        throw error
      }
    }
  ),
  getInsuranceDetailAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getInsuranceDetail'),
    async (insuranceId: string) => {
      try {
        const result = await AxiosService.get<GetInsuranceDetailResponse>(HOME_URLS.getInsuranceDetail(insuranceId))
        return result.data.insurance
      } catch (error) {
        throw error
      }
    }
  ),
  getYearMasterDataAsyncThunk: createAsyncThunk(createThunkName(PREFIX, 'getYearMasterData'), async () => {
    try {
      const result = await AxiosService.get<GetYearMasterDataResponse>(HOME_URLS.masterData.getYearMasterData)
      return result.data.years
    } catch (error) {
      throw error
    }
  }),
  getBuzzybeeUrl: async () => {
    try {
      const result = await AxiosService.get<GetBuzzybeeUrlResponse>(HOME_URLS.getBuzzybeeUrl)
      return result.data.url
    } catch (error) {
      throw error
    }
  },
  acceptPDPA: async (dto: AcceptPDPADto) => {
    try {
      await AxiosService.post(GENERAL_URLS.acceptPDPA, dto)
    } catch (error) {
      throw error
    }
  },
  acceptMedicalConsent: async (dto: AcceptMedicalConsentDto) => {
    try {
      await AxiosService.post(HOME_URLS.acceptMedicalConsent, dto)
    } catch (error) {
      throw error
    }
  },
  updateEmployeeLogo: async (dto: UploadEmployeeImageDto) => {
    try {
      await AxiosService.put(HOME_URLS.uploadEmployeeImage, dto)
    } catch (error) {
      throw error
    }
  },
  getEmployeePointHistory: async () => {
    try {
      const result = await AxiosService.get<GetEmployeePointHistoryResponse>(HOME_URLS.getEmployeePointHistory)
      return result.data
    } catch (error) {
      throw error
    }
  }
}
