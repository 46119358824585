import { Box, styled } from '@mui/material'
import React from 'react'
import { Outlet } from 'react-router'

import AuthBackgroundImage from '../components/project/images/AuthBackground'

const EmployeeLayout = () => {
  return (
    <EmployeeLayoutContainer id='body'>
      <Outlet />
    </EmployeeLayoutContainer>
  )
}

const EmployeeLayoutContainer = styled(Box)(({ theme }) => ({
  background: `url(${AuthBackgroundImage})`,
  backgroundRepeat: 'round',
  height: '100vh',
  width: '100vw',
  backgroundSize: 'cover',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(10, 4)
  }
}))

export default EmployeeLayout
