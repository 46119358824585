import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { transformMasterData } from '../../../utils/transformMasterData'
import { HomeService } from '../services/HomeService'
import { ChildrenChoosePlan, CoupleChoosePlan, EmployeeChoosePlanDto, ISelectPlan } from '../services/types'
import { HomeState } from './types'

const initialState: HomeState = {
  //State
  isEmployeeChoosePlanLoading: false,
  isFetchedEmployeeInfoLoading: false,
  isFetchedEmployeeInsuranceDetailLoading: false,
  isFetchedCoupleInfoLoading: false,
  isCoupleChoosePlanLoading: false,
  isFetchedChildrenInfoLoading: false,
  isChildrenChoosePlanLoading: false,
  isFetchedInsuranceForCoupleAndChildrenLoading: false,
  isFetchedEnrollmentSelectionDateLoading: false,
  isFetchedInsuranceDetail: false,
  isFetchedYearMasterDataLoading: false,

  //Response
  fetchedEmployeeInsuranceDetail: [],
  fetchedChildrenInfo: [],
  fetchedInsuranceForCoupleAndChildren: [],

  //Master Data
  fetchedYearMasterData: []
}

const slice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    setCoupleChoosePlanDto: (state, action: PayloadAction<CoupleChoosePlan>) => {
      state.coupleChoosePlanDto = action.payload
    },
    setChildrenChoosePlanDto: (state, action: PayloadAction<ChildrenChoosePlan>) => {
      state.childrenChoosePlanDto = action.payload
    },
    setEmployeeChoosePlanDto: (state, action: PayloadAction<EmployeeChoosePlanDto>) => {
      state.employeeChoosePlanDto = action.payload
    },
    setInsuranceId: (state, action: PayloadAction<string>) => {
      state.insuranceId = action.payload
    },
    setYearId: (state, action: PayloadAction<string>) => {
      state.yearId = action.payload
    },
    setPlanInsurance: (state, action: PayloadAction<ISelectPlan>) => {
      state.selectPlan = action.payload
    },
    resetHomeState: () => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(HomeService.employeeChoosePlanAsyncThunk.pending, (state) => {
        state.isEmployeeChoosePlanLoading = true
      })
      .addCase(HomeService.employeeChoosePlanAsyncThunk.rejected, (state) => {
        state.isEmployeeChoosePlanLoading = false
      })
      .addCase(HomeService.employeeChoosePlanAsyncThunk.fulfilled, (state) => {
        state.isEmployeeChoosePlanLoading = false
      })
      .addCase(HomeService.getEmployeeInfoAsyncThunk.pending, (state) => {
        state.isFetchedEmployeeInfoLoading = true
      })
      .addCase(HomeService.getEmployeeInfoAsyncThunk.rejected, (state) => {
        state.isFetchedEmployeeInfoLoading = false
      })
      .addCase(HomeService.getEmployeeInfoAsyncThunk.fulfilled, (state, action) => {
        state.fetchedEmployeeInfo = action.payload
        state.isFetchedEmployeeInfoLoading = false
      })
      .addCase(HomeService.getEmployeeInsuranceDetailAsyncThunk.pending, (state) => {
        state.isFetchedEmployeeInsuranceDetailLoading = true
      })
      .addCase(HomeService.getEmployeeInsuranceDetailAsyncThunk.rejected, (state) => {
        state.isFetchedEmployeeInsuranceDetailLoading = false
      })
      .addCase(HomeService.getEmployeeInsuranceDetailAsyncThunk.fulfilled, (state, action) => {
        state.fetchedEmployeeInsuranceDetail = action.payload
        state.isFetchedEmployeeInsuranceDetailLoading = false
      })
      .addCase(HomeService.getCoupleInfoAsyncThunk.pending, (state) => {
        state.isFetchedCoupleInfoLoading = true
      })
      .addCase(HomeService.getCoupleInfoAsyncThunk.rejected, (state) => {
        state.isFetchedCoupleInfoLoading = false
      })
      .addCase(HomeService.getCoupleInfoAsyncThunk.fulfilled, (state, action) => {
        state.fetchedCoupleInfo = action.payload
        state.isFetchedCoupleInfoLoading = false
      })
      .addCase(HomeService.coupleChoosePlanAsyncThunk.pending, (state) => {
        state.isCoupleChoosePlanLoading = true
      })
      .addCase(HomeService.coupleChoosePlanAsyncThunk.rejected, (state) => {
        state.isCoupleChoosePlanLoading = false
      })
      .addCase(HomeService.coupleChoosePlanAsyncThunk.fulfilled, (state) => {
        state.isCoupleChoosePlanLoading = false
      })
      .addCase(HomeService.getChildrenInfoAsyncThunk.pending, (state) => {
        state.isFetchedChildrenInfoLoading = true
      })
      .addCase(HomeService.getChildrenInfoAsyncThunk.rejected, (state) => {
        state.isFetchedChildrenInfoLoading = false
      })
      .addCase(HomeService.getChildrenInfoAsyncThunk.fulfilled, (state, action) => {
        state.fetchedChildrenInfo = action.payload
        state.isFetchedChildrenInfoLoading = false
      })
      .addCase(HomeService.childrenChoosePlanAsyncThunk.pending, (state) => {
        state.isChildrenChoosePlanLoading = true
      })
      .addCase(HomeService.childrenChoosePlanAsyncThunk.rejected, (state) => {
        state.isChildrenChoosePlanLoading = false
      })
      .addCase(HomeService.childrenChoosePlanAsyncThunk.fulfilled, (state) => {
        state.isChildrenChoosePlanLoading = false
      })
      .addCase(HomeService.getInsuranceForCoupleAndChildrenAsyncThunk.pending, (state) => {
        state.isFetchedInsuranceForCoupleAndChildrenLoading = true
      })
      .addCase(HomeService.getInsuranceForCoupleAndChildrenAsyncThunk.rejected, (state) => {
        state.isFetchedInsuranceForCoupleAndChildrenLoading = false
      })
      .addCase(HomeService.getInsuranceForCoupleAndChildrenAsyncThunk.fulfilled, (state, action) => {
        state.fetchedInsuranceForCoupleAndChildren = action.payload
        state.isFetchedInsuranceForCoupleAndChildrenLoading = false
      })
      .addCase(HomeService.getEnrollmentSelectionDateAsyncThunk.pending, (state) => {
        state.isFetchedEnrollmentSelectionDateLoading = true
      })
      .addCase(HomeService.getEnrollmentSelectionDateAsyncThunk.rejected, (state) => {
        state.isFetchedEnrollmentSelectionDateLoading = false
      })
      .addCase(HomeService.getEnrollmentSelectionDateAsyncThunk.fulfilled, (state, action) => {
        state.fetchedEnrollmentSelectionDate = action.payload
        state.isFetchedEnrollmentSelectionDateLoading = false
      })
      .addCase(HomeService.getInsuranceDetailAsyncThunk.pending, (state) => {
        state.isFetchedInsuranceDetail = true
      })
      .addCase(HomeService.getInsuranceDetailAsyncThunk.rejected, (state) => {
        state.isFetchedInsuranceDetail = false
      })
      .addCase(HomeService.getInsuranceDetailAsyncThunk.fulfilled, (state, action) => {
        state.fetchedInsuranceDetail = action.payload
        state.isFetchedInsuranceDetail = false
      })
      .addCase(HomeService.getYearMasterDataAsyncThunk.pending, (state) => {
        state.isFetchedYearMasterDataLoading = true
      })
      .addCase(HomeService.getYearMasterDataAsyncThunk.rejected, (state) => {
        state.isFetchedYearMasterDataLoading = false
      })
      .addCase(HomeService.getYearMasterDataAsyncThunk.fulfilled, (state, action) => {
        state.isFetchedYearMasterDataLoading = false
        state.fetchedYearMasterData = transformMasterData(action.payload)
      })
  }
})

export const {
  resetHomeState,
  setCoupleChoosePlanDto,
  setChildrenChoosePlanDto,
  setEmployeeChoosePlanDto,
  setInsuranceId,
  setYearId,
  setPlanInsurance
} = slice.actions
export default slice.reducer
