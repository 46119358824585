import { Box, styled, Typography, useMediaQuery, useTheme } from '@mui/material'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useInView } from 'react-intersection-observer'

import FlexbenLightLogo from '../components/project/images/FlexbenLightLogo'
import { COLORS_THEME } from '../constants/colors'
import { FOOTER_HEIGHT } from '../constants/constants'
import { i18nKey } from '../constants/i18n-key'
import { useAppDispatch } from '../stores/store'
import { setFooterVisible } from '../stores/visible/slice'

const Footer = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const { ref: footerRef, inView: isFooterVisible } = useInView()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  useEffect(() => {
    dispatch(setFooterVisible(isFooterVisible))
  }, [isFooterVisible])

  return (
    <Box component='footer' ref={footerRef}>
      <FooterContainer>
        <Box display='flex' justifyContent='space-between' flexDirection={isMobile ? 'column' : 'row'}>
          <Box>
            <LogoContainer src={FlexbenLightLogo} />
            <Typography
              variant='body1'
              color='white'
              marginTop={4}
              width={isMobile ? '100%' : '75%'}
              fontWeight='normal'
            >
              388 อาคารอามีโก้ทาวเวอร์ ชั้น 28 โซนบี/1 ถนนสี่พระยา แขวงมหาพฤฒาราม เขตบางรัก กรุงเทพมหานคร 10500
            </Typography>
          </Box>
          <Box alignSelf='flex-end' marginTop={isMobile ? 4 : 0}>
            <Typography variant='body1' color='white' textAlign={isMobile ? 'start' : 'end'} fontWeight='normal'>
              {t(i18nKey.customer_service)} : 02-114-7534
            </Typography>
            <Typography variant='body1' color='white' fontWeight='normal'>
              © 2020 FlexBen Company Limited. All rights reserved
            </Typography>
          </Box>
        </Box>
      </FooterContainer>
    </Box>
  )
}

const LogoContainer = styled('img')(() => ({
  width: '129px',
  height: '24px'
}))

const FooterContainer = styled(Box)(({ theme }) => ({
  height: FOOTER_HEIGHT,
  backgroundColor: COLORS_THEME.primary,
  padding: theme.spacing(8, 33),
  [theme.breakpoints.down('md')]: {
    height: 'auto',
    padding: theme.spacing(4)
  }
}))

export default Footer
