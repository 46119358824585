import { Avatar, Box, Stack, styled, Typography, useMediaQuery, useTheme } from '@mui/material'
import zIndex from '@mui/material/styles/zIndex'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'

import { COLORS, COLORS_THEME } from '../../../constants/colors'
import { MOBILE_NAVBAR_HEIGHT, NAVBAR_HEIGHT } from '../../../constants/constants'
import { i18nKey } from '../../../constants/i18n-key'
import { homePath } from '../../../constants/route-name'
import { AuthService } from '../../../features/auth/services/AuthService'
import { currentUserSelector } from '../../../stores/current-user/selector'
import { resetUser } from '../../../stores/current-user/slice'
import { isScrollTopSelector } from '../../../stores/visible/selector'
import { combineImageUrl } from '../../../utils/combineImageUrl'
import { convertToCurrency } from '../../../utils/convertToCurrency'
import AutoCompleteSelectField, { AutoCompleteSelectInputStyle } from '../../global/forms/AutoCompleteSelectField'
import BurgerIcon from '../../global/icons/BurgerIcon'
import CancelIcon from '../../global/icons/CancelIcon'
import ConfirmDialog from '../../global/interactions/ConfirmDialog'
import CustomButton from '../../global/interactions/CustomButton'
import LogoutIcon from '../icons/LogoutIcon'
import PointIcon from '../icons/PointIcon'
import FlexBenLogo from '../images/FlexBenLogo'

const Navbar = () => {
  const [isExpand, setIsExpand] = useState(false)
  const [isConfirmLogoutDialogOpen, setIsConfirmLogoutDialogOpen] = useState(false)

  const dispatch = useDispatch()
  const theme = useTheme()
  const navigate = useNavigate()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const location = useLocation()
  const { t, i18n } = useTranslation()
  let isScrollTop = useSelector(isScrollTopSelector)
  const currentUser = useSelector(currentUserSelector)

  const currentLanguage = localStorage.getItem('i18nextLng')!
  const languageOptions = [
    { id: 'en-US', label: isMobile ? 'EN' : 'English' },
    { id: 'th', label: isMobile ? 'TH' : 'ภาษาไทย' }
  ]

  const onChangeLanguage = (value: string) => i18n.changeLanguage(value)
  const toggleExpand = () => setIsExpand((prevExpand) => !prevExpand)
  const onLogout = () => setIsConfirmLogoutDialogOpen(true)
  const onConfirm = () => {
    AuthService.logout()
    dispatch(resetUser())
  }
  const onCancel = () => setIsConfirmLogoutDialogOpen(false)

  if (location.pathname !== '/') isScrollTop = false

  return (
    <>
      <ConfirmDialog
        title='ออกจากระบบ'
        size='small'
        content={
          <Box padding={6}>
            <Typography variant='h6' textAlign='center'>
              คุณต้องการที่จะออกจากระบบหรือไม่?
            </Typography>
          </Box>
        }
        isOpen={isConfirmLogoutDialogOpen}
        onConfirm={onConfirm}
        onCancel={onCancel}
      />
      <NavbarContainer
        bgcolor={isScrollTop ? 'red' : COLORS.white}
        borderBottom={isScrollTop ? 0 : 1}
        borderColor={COLORS.grey600}
      >
        <LogoContainer src={FlexBenLogo} sx={{ cursor: 'pointer' }} onClick={() => navigate(homePath)} />
        {isMobile ? (
          <Box display='flex' justifyContent='center' alignItems='center' onClick={toggleExpand}>
            {isExpand ? <CancelIcon size={24} color={COLORS_THEME.primary} /> : <BurgerIcon />}
          </Box>
        ) : (
          <ToolbarContainer>
            <Box display='flex' alignItems='center' zIndex={1000}>
              {/* <NavbarButtonList /> */}
              <Stack direction='row' spacing={2} alignItems='center' marginLeft={12}>
                <Typography variant='subtitle1' color={COLORS.grey600}>
                  {t(i18nKey.already_have)}
                </Typography>
                <Box display='flex' alignItems='center'>
                  <PointIcon size={16} />
                  <Typography color={COLORS_THEME.primary} fontWeight={600} marginX={2}>
                    {convertToCurrency(currentUser ? currentUser.point : 0, true)}
                  </Typography>
                  <Typography color={COLORS_THEME.primary} fontWeight='normal'>
                    {t(i18nKey.point)}
                  </Typography>
                </Box>
                <StyledAvatar src={currentUser ? combineImageUrl(currentUser?.profileUrl) : undefined} />
                <Box width='130px'>
                  <AutoCompleteSelectField
                    label={t(i18nKey.form_field_label.language)}
                    value={currentLanguage}
                    options={languageOptions}
                    onChange={onChangeLanguage}
                    style={AutoCompleteSelectInputStyle.Secondary}
                  />
                </Box>
                <LogoutBox onClick={onLogout}>
                  <LogoutIcon />
                </LogoutBox>
              </Stack>
            </Box>
          </ToolbarContainer>
        )}
      </NavbarContainer>
      <ExpandBox top={isExpand ? 72 : '-100vh'}>
        <Stack spacing={4}>
          <AutoCompleteSelectField
            label={t(i18nKey.form_field_label.language)}
            value={currentLanguage}
            options={languageOptions}
            onChange={onChangeLanguage}
            style={AutoCompleteSelectInputStyle.Secondary}
          />
          <CustomButton text={t(i18nKey.button_label.log_out)} variant='outlined' onClick={onLogout} />
        </Stack>
      </ExpandBox>
    </>
  )
}

const LogoutBox = styled(Box)(({ theme }) => ({
  width: avatarSize,
  height: avatarSize,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: '50%',
  cursor: 'pointer'
}))

const avatarSize = '36px'
const StyledAvatar = styled(Avatar)(() => ({
  width: avatarSize,
  height: avatarSize
}))

const LogoContainer = styled('img')(() => ({
  width: '204px',
  height: '40px'
}))

const ToolbarContainer = styled(Stack)(() => ({
  alignItems: 'center'
}))

ToolbarContainer.defaultProps = {
  spacing: 8,
  direction: 'row'
}

const ExpandBox = styled(Box)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(6, 17),
  position: 'absolute',
  left: 0,
  backgroundColor: COLORS.white,
  transition: 'top .5s',
  zIndex: 10,
  borderBottom: `1px solid ${COLORS.grey100}`
}))

const NavbarContainer = styled(Box)(({ theme }) => ({
  height: NAVBAR_HEIGHT,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 33, 0, 33),
  position: 'sticky',
  top: 0,
  width: '100%',
  zIndex: zIndex.appBar,
  transition: 'background-color .3s',
  [theme.breakpoints.down('md')]: {
    height: MOBILE_NAVBAR_HEIGHT,
    padding: theme.spacing(4),
    borderBottom: `1px solid ${COLORS.grey600}`
  }
}))

export default Navbar
