import { Autocomplete, AutocompleteProps, Box, styled, TextField } from '@mui/material'
import React from 'react'

import { COLORS } from '../../../constants/colors'
import { fontFamily, fontSize } from '../../../themes/themes'
import { Asterisk } from './CustomTextField'

const values = [
  { id: '1', label: 'Sathorn Square' },
  { id: '2', label: '2' }
]

export enum AutoCompleteSelectInputStyle {
  Primary,
  Secondary
}

interface AutoCompleteSelectInputProps {
  label?: string
  value: string
  onChange: (value: string) => void
  error?: boolean
  helperText?: string | false | undefined
  isDisabled?: boolean
  placeholder?: string
  width?: string
  options?: { id: string; label: string }[]
  isNeedAll?: boolean
  isLoading?: boolean
  isRequired?: boolean
  disableClearable?: boolean
  style?: AutoCompleteSelectInputStyle
}

interface StyledAutoCompleteSelectInputProps
  extends AutocompleteProps<{ label: string; id: string } | null, false, true | false, false> {
  isError?: boolean
  customWidth?: string
  required?: boolean
}

const AutoCompleteSelectInput = ({
  isDisabled,
  placeholder,
  onChange,
  error,
  helperText,
  value,
  width,
  options = values,
  isNeedAll,
  isLoading,
  label,
  isRequired,
  style = AutoCompleteSelectInputStyle.Primary,
  disableClearable = true
}: AutoCompleteSelectInputProps) => {
  const newOptions = isNeedAll ? [...options, { id: 'ทั้งหมด', label: 'ทั้งหมด' }] : options
  const valueObj = !value ? null : newOptions.find((item) => item.id === value)!

  const isPrimary = style === AutoCompleteSelectInputStyle.Primary
  const StyledAutocomplete = isPrimary ? PrimaryAutocomplete : SecondaryAutocomplete

  const handleOnChange = (_: any, value: unknown) => {
    if (value) {
      if (onChange) onChange((value as any).id)
    } else {
      onChange('')
    }
  }

  return (
    <Box position='relative' width={width}>
      <StyledAutocomplete
        fullWidth
        required={isRequired}
        disabled={isDisabled}
        loading={isLoading}
        options={newOptions}
        disableClearable={disableClearable}
        onChange={handleOnChange}
        isError={error}
        getOptionLabel={(option: any) => (option as any).label}
        value={valueObj as any}
        renderInput={(params) => (
          <TextField
            {...params}
            required={isRequired}
            InputLabelProps={{ shrink: false }}
            label={label}
            placeholder={placeholder}
            error={error}
            helperText={error ? helperText : ''}
          />
        )}
      />
      {style === AutoCompleteSelectInputStyle.Secondary && isRequired && valueObj && <Asterisk>*</Asterisk>}
    </Box>
  )
}

const PrimaryAutocomplete = styled(Autocomplete, {
  shouldForwardProp: (prop) => prop !== 'isError' && prop !== 'customWidth'
})<StyledAutoCompleteSelectInputProps>(({ theme, isError, customWidth, value }) => ({
  width: customWidth,
  minWidth: '0px',
  '& .MuiOutlinedInput-root': {
    padding: 0,
    borderRadius: theme.spacing(2),
    backgroundColor: COLORS.white
  },
  '& .MuiInputBase-root.Mui-disabled': {
    backgroundColor: COLORS.grey200,
    color: COLORS.grey200
  },
  '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
    padding: theme.spacing(4.5, 2.5, 1.5, 2.5),
    fontSize: fontSize.body1
  },
  '& .MuiInputBase-root .MuiOutlinedInput-notchedOutline, & .MuiInputBase-root.Mui-disabled .MuiOutlinedInput-notchedOutline':
    {
      borderColor: isError ? theme.palette.error.main : COLORS.grey300
    },
  '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: 'black'
  },
  '& .Mui-disabled:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: COLORS.grey300
  },
  '& .Mui-focused:hover .MuiOutlinedInput-notchedOutline, & .Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.primary.main
  },
  '& .MuiFormHelperText-root.Mui-error': {
    fontSize: fontSize.subtitle1,
    fontFamily: fontFamily
  },
  '& .MuiInputLabel-root': {
    fontSize: value && value.label.length > 0 ? fontSize.subtitle1 : fontSize.body1,
    fontWeight: value && value.label.length > 0 ? 500 : 'normal',
    color: COLORS.grey500,
    transform:
      value && value.label.length > 0
        ? `translate(${theme.spacing(2.5)},${theme.spacing(1.5)})`
        : `translate(${theme.spacing(2.5)},${theme.spacing(3.25)})`
  },
  '& .MuiInputLabel-root .MuiInputLabel-asterisk': {
    color: COLORS.red400,
    marginRight: theme.spacing(0.5)
  },
  '& .MuiInputLabel-root.Mui-required': {
    display: 'flex',
    flexDirection: 'row-reverse'
  },
  '& .MuiInputLabel-root.Mui-focused': {
    fontSize: fontSize.subtitle1,
    fontWeight: 500,
    color: COLORS.grey500,
    transform: `translate(${theme.spacing(2.5)},${theme.spacing(1.5)})`
  }
}))

const SecondaryAutocomplete = styled(Autocomplete, {
  shouldForwardProp: (prop) => prop !== 'isError' && prop !== 'customWidth' && prop !== 'required'
})<StyledAutoCompleteSelectInputProps>(({ theme, isError, customWidth, value, required }) => ({
  width: customWidth,
  minWidth: '0px',
  '& .MuiOutlinedInput-root': {
    padding: 0,
    borderRadius: theme.spacing(2),
    backgroundColor: COLORS.white
  },
  '& .MuiInputBase-root.Mui-disabled': {
    backgroundColor: COLORS.grey200,
    color: COLORS.grey200
  },
  '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
    fontSize: fontSize.body1,
    padding: value && value.label.length > 0 && required ? theme.spacing(2.5, 6) : theme.spacing(2.5, 4)
  },
  '& .MuiInputBase-root .MuiOutlinedInput-notchedOutline, & .MuiInputBase-root.Mui-disabled .MuiOutlinedInput-notchedOutline':
    {
      borderColor: isError ? theme.palette.error.main : COLORS.grey300
    },
  '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: 'black'
  },
  '& .Mui-disabled:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: COLORS.grey300
  },
  '& .Mui-focused:hover .MuiOutlinedInput-notchedOutline, & .Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.primary.main
  },
  '& .MuiFormHelperText-root.Mui-error': {
    fontSize: fontSize.subtitle1,
    fontFamily: fontFamily
  },
  '& .MuiInputLabel-root .MuiInputLabel-asterisk': {
    color: COLORS.red400,
    marginRight: theme.spacing(0.5)
  },
  '& .MuiInputLabel-root.Mui-required': {
    display: 'flex',
    flexDirection: 'row-reverse'
  },
  '& .MuiInputLabel-root': {
    transition: 'opacity .2s',
    opacity: value ? 0 : 1,
    top: '-5px',
    left: '2px',
    color: COLORS.grey500
  }
}))

export default React.memo(AutoCompleteSelectInput)
