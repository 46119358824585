import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import DentalImage from '../../../components/project/images/DentalImage'
import IPDImage from '../../../components/project/images/IPDImage'
import OPDImage from '../../../components/project/images/OPDImage'
import { ClaimService } from '../services/ClaimService'
import { ClaimState, SelectInsuranceClaimDocumentParams } from './types'

const initialState: ClaimState = {
  isFetchedAllClaimLoading: false,
  isFetchedAllHistoryClaimLoading: false,
  isFetchedClaimDetailByIdLoading: false,
  isFetchedHistoryByClaimIdLoading: false,
  isCreateRequestClaimLoading: false,
  isFetchedRequestClaimByHistoryClaimIdLoading: false,
  isFetchedHistoryByHistoryClaimIdLoading: false,
  isUpdateRequestClaimDetailByIdLoading: false,
  isEditClaimRequest: false,
  isCancelClaimRequestLoading: false,
  insuranceClaimDocuments: []
}

const slice = createSlice({
  name: 'claim',
  initialState,
  reducers: {
    selectInsuranceClaimDocument: (state, action: PayloadAction<SelectInsuranceClaimDocumentParams>) => {
      const findIndex = state.insuranceClaimDocuments.findIndex(
        (doc) => doc.id === action.payload.insuranceClaimDocumentId
      )
      state.insuranceClaimDocuments[findIndex] = {
        ...state.insuranceClaimDocuments[findIndex],
        file: action.payload.file
      }
    },
    setIsEditClaimRequest: (state, action: PayloadAction<boolean>) => {
      state.isEditClaimRequest = action.payload
    },
    resetClaimState: () => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(ClaimService.getAllClaimAsyncThunk.pending, (state) => {
        state.isFetchedAllClaimLoading = true
      })
      .addCase(ClaimService.getAllClaimAsyncThunk.rejected, (state) => {
        state.isFetchedAllClaimLoading = false
      })
      .addCase(ClaimService.getAllClaimAsyncThunk.fulfilled, (state, action) => {
        state.fetchedAllClaim = {
          claim: action.payload.claim,
          claimTypes: action.payload.claimTypes.map((claimType) => {
            const claimIconUrl = (() => {
              if (claimType.claimId === '3be6a690-0759-4224-ac10-4b3e06a543d9') {
                return IPDImage
              } else if (claimType.claimId === '420efec4-853a-4f7c-a43e-9e0d07cfdf94') {
                return OPDImage
              } else {
                return DentalImage
              }
            })()
            return {
              ...claimType,
              claimIconUrl
            }
          })
        }
        state.isFetchedAllClaimLoading = false
      })
      .addCase(ClaimService.getAllHistoryClaimAsyncThunk.pending, (state) => {
        state.isFetchedAllHistoryClaimLoading = true
      })
      .addCase(ClaimService.getAllHistoryClaimAsyncThunk.rejected, (state) => {
        state.isFetchedAllHistoryClaimLoading = false
      })
      .addCase(ClaimService.getAllHistoryClaimAsyncThunk.fulfilled, (state, action) => {
        state.fetchedAllHistoryClaim = action.payload
        state.isFetchedAllHistoryClaimLoading = false
      })
      .addCase(ClaimService.getClaimDetailByIdAsyncThunk.pending, (state) => {
        state.isFetchedClaimDetailByIdLoading = true
      })
      .addCase(ClaimService.getClaimDetailByIdAsyncThunk.rejected, (state) => {
        state.isFetchedClaimDetailByIdLoading = false
      })
      .addCase(ClaimService.getClaimDetailByIdAsyncThunk.fulfilled, (state, action) => {
        state.isFetchedClaimDetailByIdLoading = false
        state.fetchedClaimDetailById = action.payload
        state.insuranceClaimDocuments = action.payload.claimDocumentMasters.map((claimDoc) => ({
          id: claimDoc.id,
          name: claimDoc.name,
          file: null,
          fileUrl: ''
        }))
      })
      .addCase(ClaimService.getHistoryByClaimIdAsyncThunk.pending, (state) => {
        state.isFetchedHistoryByClaimIdLoading = true
      })
      .addCase(ClaimService.getHistoryByClaimIdAsyncThunk.rejected, (state) => {
        state.isFetchedHistoryByClaimIdLoading = false
      })
      .addCase(ClaimService.getHistoryByClaimIdAsyncThunk.fulfilled, (state, action) => {
        state.isFetchedHistoryByClaimIdLoading = false
        state.fetchedHistoryByClaimId = action.payload
      })
      .addCase(ClaimService.createRequestClaimAsyncThunk.pending, (state) => {
        state.isCreateRequestClaimLoading = true
      })
      .addCase(ClaimService.createRequestClaimAsyncThunk.rejected, (state) => {
        state.isCreateRequestClaimLoading = false
      })
      .addCase(ClaimService.createRequestClaimAsyncThunk.fulfilled, (state) => {
        state.isCreateRequestClaimLoading = false
      })
      .addCase(ClaimService.getRequestClaimByHistoryClaimIdAsyncThunk.pending, (state) => {
        state.isFetchedRequestClaimByHistoryClaimIdLoading = true
      })
      .addCase(ClaimService.getRequestClaimByHistoryClaimIdAsyncThunk.rejected, (state) => {
        state.isFetchedRequestClaimByHistoryClaimIdLoading = false
      })
      .addCase(ClaimService.getRequestClaimByHistoryClaimIdAsyncThunk.fulfilled, (state, action) => {
        state.isFetchedRequestClaimByHistoryClaimIdLoading = false
        state.fetchedRequestClaimByHistoryClaimId = action.payload
        state.insuranceClaimDocuments = action.payload.claimDocumentMasters.map((claimDoc) => {
          const currentDocument = action.payload.requestClaimDetail.documents.find(
            (document) => document.claimDocumentMasterId === claimDoc.id
          )
          return {
            fileUrl: currentDocument ? currentDocument.fileUrl : '',
            id: claimDoc.id,
            name: claimDoc.name,
            file: null
          }
        })
      })
      .addCase(ClaimService.getHistoryByHistoryClaimIdAsyncThunk.pending, (state) => {
        state.isFetchedHistoryByHistoryClaimIdLoading = true
      })
      .addCase(ClaimService.getHistoryByHistoryClaimIdAsyncThunk.rejected, (state) => {
        state.isFetchedHistoryByHistoryClaimIdLoading = false
      })
      .addCase(ClaimService.getHistoryByHistoryClaimIdAsyncThunk.fulfilled, (state, action) => {
        state.isFetchedHistoryByHistoryClaimIdLoading = false
        state.fetchedHistoryByHistoryClaimId = action.payload
      })
      .addCase(ClaimService.updateRequestClaimDetailByIdAsyncThunk.pending, (state) => {
        state.isUpdateRequestClaimDetailByIdLoading = true
      })
      .addCase(ClaimService.updateRequestClaimDetailByIdAsyncThunk.rejected, (state) => {
        state.isUpdateRequestClaimDetailByIdLoading = false
      })
      .addCase(ClaimService.updateRequestClaimDetailByIdAsyncThunk.fulfilled, (state) => {
        state.isUpdateRequestClaimDetailByIdLoading = false
      })
      .addCase(ClaimService.cancelClaimRequestAsyncThunk.pending, (state) => {
        state.isCancelClaimRequestLoading = true
      })
      .addCase(ClaimService.cancelClaimRequestAsyncThunk.rejected, (state) => {
        state.isCancelClaimRequestLoading = false
      })
      .addCase(ClaimService.cancelClaimRequestAsyncThunk.fulfilled, (state) => {
        state.isCancelClaimRequestLoading = false
      })
  }
})

export const { selectInsuranceClaimDocument, resetClaimState, setIsEditClaimRequest } = slice.actions
export default slice.reducer
