import { createAsyncThunk } from '@reduxjs/toolkit'

import { CLAIM_URLS } from '../../../configs/api'
import { AxiosService } from '../../../services/api'
import { createThunkName } from '../../../utils/createThunkname'
import {
  CancelClaimRequestDto,
  CreateRequestClaimDto,
  GetAllClaimResponse,
  GetAllHistoryClaimResponse,
  GetClaimDetailByIdResponse,
  GetHistoryByClaimIdResponse,
  GetHistoryByHistoryClaimIdResponse,
  GetHistoryClaimQueryParams,
  GetRequestClaimByHistoryClaimIdResponse,
  UpdateRequestClaimDetailByIdDto
} from './types'

const PREFIX = 'claim'

export const ClaimService = {
  getAllClaimAsyncThunk: createAsyncThunk(createThunkName(PREFIX, 'getAllClaim'), async () => {
    try {
      const result = await AxiosService.get<GetAllClaimResponse>(CLAIM_URLS.getAllClaim)
      return result.data
    } catch (error) {
      throw error
    }
  }),
  getAllHistoryClaimAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getAllHistoryClaim'),
    async (queryParams: GetHistoryClaimQueryParams) => {
      try {
        const result = await AxiosService.get<GetAllHistoryClaimResponse>(CLAIM_URLS.getAllHistoryClaim, {
          params: queryParams
        })
        return result.data
      } catch (error) {
        throw error
      }
    }
  ),
  getClaimDetailByIdAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getClaimDetailById'),
    async (claimId: string) => {
      try {
        const result = await AxiosService.get<GetClaimDetailByIdResponse>(CLAIM_URLS.getClaimDetailById(claimId))
        return result.data
      } catch (error) {
        throw error
      }
    }
  ),
  getHistoryByClaimIdAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getHistoryByClaimId'),
    async ({ queryParams, claimId }: { queryParams: GetHistoryClaimQueryParams; claimId: string }) => {
      try {
        const result = await AxiosService.get<GetHistoryByClaimIdResponse>(CLAIM_URLS.getHistoryByClaimId(claimId), {
          params: queryParams
        })
        return result.data
      } catch (error) {
        throw error
      }
    }
  ),
  createRequestClaimAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'createRequestClaim'),
    async (dto: CreateRequestClaimDto) => {
      try {
        await AxiosService.post(CLAIM_URLS.createRequestClaim, dto)
      } catch (error) {
        throw error
      }
    }
  ),
  updateRequestClaimDetailByIdAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'updateRequestClaimDetailById'),
    async (dto: UpdateRequestClaimDetailByIdDto) => {
      try {
        await AxiosService.put(CLAIM_URLS.updateRequestClaimDetailById, dto)
      } catch (error) {
        throw error
      }
    }
  ),
  getRequestClaimByHistoryClaimIdAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getRequestClaimByHistoryClaimId'),
    async (historyClaimId: string) => {
      try {
        const result = await AxiosService.get<GetRequestClaimByHistoryClaimIdResponse>(
          CLAIM_URLS.getRequestClaimByHistoryClaimId(historyClaimId)
        )
        return result.data
      } catch (error) {
        throw error
      }
    }
  ),
  getHistoryByHistoryClaimIdAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getHistoryByHistoryClaimId'),
    async ({ queryParams, historyClaimId }: { queryParams: GetHistoryClaimQueryParams; historyClaimId: string }) => {
      try {
        const result = await AxiosService.get<GetHistoryByHistoryClaimIdResponse>(
          CLAIM_URLS.getHistoryByHistoryClaimId(historyClaimId),
          { params: queryParams }
        )
        return result.data
      } catch (error) {
        throw error
      }
    }
  ),
  cancelClaimRequestAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'cancelClaimRequest'),
    async (dto: CancelClaimRequestDto) => {
      try {
        await AxiosService.post(CLAIM_URLS.cancelClaimRequest, dto)
      } catch (error) {
        throw error
      }
    }
  )
}
