export const baseApiUrl = `${process.env.REACT_APP_BASE_URL}/employee`

export const GENERAL_URLS = {
  acceptPDPA: `${process.env.REACT_APP_BASE_URL}/general/pdpa/accept`
}

export const AUTHENTICATION_URLS = {
  requestOtp: `${baseApiUrl}/request-otp`,
  confirmOtp: `${baseApiUrl}/confirm-otp`,
  resendOtp: `${baseApiUrl}/resend-otp`,
  getCustomerInfo: (customerId: string) => `${baseApiUrl}/${customerId}/info`,
  getCurrentEmployee: `${baseApiUrl}/current`
}

export const HOME_URLS = {
  acceptMedicalConsent: `${baseApiUrl}/medical/consent`,
  uploadEmployeeImage: `${baseApiUrl}/images`,
  employeeChoosePlan: `${baseApiUrl}/choose-plan`,
  getBuzzybeeUrl: `${baseApiUrl}/benefits/bzb/url`,
  getEmployeeInfo: (yearId: string) => `${baseApiUrl}/${yearId}/employee/info`,
  getEmployeeInsuranceDetail: (yearId: string) => `${baseApiUrl}/${yearId}/insurances/detail`,
  getCoupleInfo: (yearId: string) => `${baseApiUrl}/${yearId}/couple/info`,
  coupleChoosePlan: `${baseApiUrl}/couple/choose-plan`,
  getChildrenInfo: (yearId: string) => `${baseApiUrl}/${yearId}/children/info`,
  childrenChoosePlan: `${baseApiUrl}/children/choose-plan`,
  getInsuranceForCoupleAndChildren: (yearId: string) => `${baseApiUrl}/${yearId}/insurances`,
  getEnrollmentSelectionDate: (yearId: string) => `${baseApiUrl}/${yearId}/enrollment`,
  getInsuranceDetail: (insuranceId: string) => `${baseApiUrl}/insurances/${insuranceId}/detail`,
  getEmployeePointHistory: `${baseApiUrl}/point/histories`,
  masterData: {
    getYearMasterData: `${baseApiUrl}/years/master-data`
  }
}

const baseCompanyWelfareUrl = `${baseApiUrl}/benefits`
export const COMPANY_WELFARE_URLS = {
  getAllBenefitByCategory: baseCompanyWelfareUrl,
  getHistoryUsedPoint: `${baseCompanyWelfareUrl}/histories`,
  getBenefitDetailById: (benefitId: string) => `${baseCompanyWelfareUrl}/${benefitId}`,
  createRequestWelfare: baseCompanyWelfareUrl,
  getRequestWelfareByHistoryId: (historyId: string) => `${baseCompanyWelfareUrl}/${historyId}/detail`,
  updateRequestWelfareDetailById: baseCompanyWelfareUrl,
  cancelClaimWelfareRequest: `${baseCompanyWelfareUrl}/cancel`
}

const baseClaimUrl = `${baseApiUrl}/claims`
export const CLAIM_URLS = {
  getAllClaim: baseClaimUrl,
  getAllHistoryClaim: `${baseClaimUrl}/histories`,
  getClaimDetailById: (claimId: string) => `${baseClaimUrl}/${claimId}`,
  getHistoryByClaimId: (claimId: string) => `${baseClaimUrl}/${claimId}/histories`,
  createRequestClaim: baseClaimUrl,
  getRequestClaimByHistoryClaimId: (historyClaimId: string) => `${baseClaimUrl}/${historyClaimId}/detail`,
  getHistoryByHistoryClaimId: (historyClaimId: string) => `${baseClaimUrl}/${historyClaimId}/detail/histories`,
  updateRequestClaimDetailById: baseClaimUrl,
  cancelClaimRequest: `${baseClaimUrl}/cancel`
}

const baseUploadUrl = `${baseApiUrl}/upload`
export const UPLOAD_URLS = {
  uploadImage: `${baseUploadUrl}/image`,
  uploadFile: `${baseUploadUrl}/file`
}

export const INSURANCE_URLS = {
  submitInsuranceSelectList: `${baseApiUrl}/insurances/selection/confirm`
}

const baseMedicalUrl = `${baseApiUrl}/medical`
export const MEDICAL_URLS = {
  getMedicalEmployeeInfo: (yearId: string) => `${baseMedicalUrl}/${yearId}/info`,
  getMedicalSummaryResult: `${baseMedicalUrl}/summary`,
  getMedicalResult: (yearId: string) => `${baseMedicalUrl}/${yearId}/medical-result`,
  getCompareMedicalResult: `${baseMedicalUrl}/medical-result/compare`,
  masterData: {
    getMedicalFilteringYearMasterData: `${baseMedicalUrl}/year/master-data`
  }
}
