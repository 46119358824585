import { createSlice } from '@reduxjs/toolkit'

import { AuthService } from '../services/AuthService'

const initialState: authState = {
  //state
  isRequestOTPLoading: false,
  isConfirmOTPLoading: false,
  isCustomerLogoLoading: false,
  //fetched
  fetchedLogoUrl: '',
  fetchedHrContactNumber: '',
  fetchedIsLoginViaEmail: false,
  fetchedIsLoginViaSMS: false
}

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(AuthService.requestOTPAsyncThunk.pending, (state) => {
        state.isRequestOTPLoading = true
      })
      .addCase(AuthService.requestOTPAsyncThunk.rejected, (state) => {
        state.isRequestOTPLoading = false
      })
      .addCase(AuthService.requestOTPAsyncThunk.fulfilled, (state, action) => {
        state.fetchedOTPDetail = action.payload
        state.isRequestOTPLoading = false
      })
      .addCase(AuthService.resendOTPAsyncThunk.pending, (state) => {
        state.isRequestOTPLoading = true
      })
      .addCase(AuthService.resendOTPAsyncThunk.rejected, (state) => {
        state.isRequestOTPLoading = false
      })
      .addCase(AuthService.resendOTPAsyncThunk.fulfilled, (state, action) => {
        state.fetchedOTPDetail = action.payload
        state.isRequestOTPLoading = false
      })
      .addCase(AuthService.confirmOTPAsyncThunk.pending, (state) => {
        state.isConfirmOTPLoading = true
      })
      .addCase(AuthService.confirmOTPAsyncThunk.rejected, (state) => {
        state.isConfirmOTPLoading = false
      })
      .addCase(AuthService.confirmOTPAsyncThunk.fulfilled, (state) => {
        state.isConfirmOTPLoading = false
      })
      .addCase(AuthService.getCustomerInfoAsyncThunk.pending, (state) => {
        state.isCustomerLogoLoading = true
      })
      .addCase(AuthService.getCustomerInfoAsyncThunk.rejected, (state) => {
        state.isCustomerLogoLoading = false
      })
      .addCase(AuthService.getCustomerInfoAsyncThunk.fulfilled, (state, action) => {
        state.isCustomerLogoLoading = false
        if (action.payload) {
          state.fetchedLogoUrl = action.payload.customerInfo.customerLogoUrl
          state.fetchedHrContactNumber = action.payload.customerInfo.hrContactNumber
          state.fetchedIsLoginViaEmail = action.payload.customerInfo.isEmployeeLoginByEmail
          state.fetchedIsLoginViaSMS = action.payload.customerInfo.isEmployeeLoginBySMS
        }
      })
  }
})

export default slice.reducer
