import {
  Box,
  Dialog,
  DialogActions,
  DialogActionsProps,
  DialogContent,
  DialogProps,
  styled,
  Typography
} from '@mui/material'
import React, { ReactNode } from 'react'

import { COLORS, COLORS_THEME } from '../../../constants/colors'
import { fontSize } from '../../../themes/themes'
import CustomButton from './CustomButton'

interface ConfirmDialogProps {
  title: string
  size: 'large' | 'small' | 'medium'
  content: ReactNode
  onConfirm: () => void
  onCancel?: () => void
  isOpen: boolean
  isConfirmDisabled?: boolean
}

interface StyledDialogActionsProps extends DialogActionsProps {
  haveCancel: boolean
}

interface StyledDialogProps extends DialogProps {
  customSize: string
}

const ConfirmDialog = ({
  content,
  onConfirm,
  onCancel,
  isOpen,
  isConfirmDisabled,
  title,
  size
}: ConfirmDialogProps) => {
  return (
    <StyledDialog open={isOpen} customSize={size}>
      <StyledDialogContent>
        <Box paddingY={4} paddingX={6} display='flex' alignItems='center' justifyContent='center'>
          <Typography variant='h5' color={COLORS_THEME.primary}>
            {title}
          </Typography>
        </Box>
        <Box borderTop={1} borderBottom={1} borderColor={COLORS.grey100}>
          {content}
        </Box>
      </StyledDialogContent>
      <StyledDialogActions haveCancel={Boolean(onCancel)}>
        {onCancel && (
          <CustomButton text='ยกเลิก' variant='outlined' size='medium' textVariant='body1' onClick={onCancel} />
        )}
        <CustomButton
          text='ยืนยัน'
          size='medium'
          isDisabled={isConfirmDisabled}
          color='primary'
          textVariant='body1'
          onClick={onConfirm}
        />
      </StyledDialogActions>
    </StyledDialog>
  )
}

const StyledDialog = styled(Dialog, { shouldForwardProp: (prop) => prop !== 'customSize' })<StyledDialogProps>(
  ({ theme, customSize }) => ({
    '& .MuiDialog-container': {
      width: '424px !important',
      position: 'absolute',
      left: '50%',
      transform: 'translateX(-50%)',
      top: '-5%',
      '& .MuiPaper-root': {
        borderRadius: `${theme.spacing(4)} !important`
      }
    },
    '& .MuiDialog-container .MuiPaper-root': {
      margin: theme.spacing(0),
      width: customSize === 'large' ? '1090px' : customSize === 'small' ? '375px' : '424px',
      maxWidth: customSize === 'large' ? '100%' : '600px'
    }
  })
)

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: theme.spacing(0),
  overflowY: 'hidden',
  fontSize: fontSize.h6
}))

const StyledDialogActions = styled(DialogActions, {
  shouldForwardProp: (props) => props !== 'haveCancel'
})<StyledDialogActionsProps>(({ theme, haveCancel }) => ({
  padding: theme.spacing(4),
  justifyContent: haveCancel ? 'center' : 'flex-end',
  alignItems: 'stretch',
  '& > :not(:first-of-type)': {
    marginLeft: theme.spacing(4)
  }
}))

export default ConfirmDialog
