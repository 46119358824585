import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { CompanyWelfareService } from '../services/CompanyWelfareService'
import { CompanyWelfareState, SelectBenefitDocumentParams } from './type'

const initialState: CompanyWelfareState = {
  isCreateRequestWelfareLoading: false,
  isGetAllBenefitByCategoryLoading: false,
  isGetBenefitDetailByIdLoading: false,
  isGetHistoryUsedPointLoading: false,
  isGetRequestWelfareByHistoryIdLoading: false,
  isUpdateRequestWelfareDetailByIdLoading: false,
  isEditWelfareRequest: false,
  isCancelRequestWelfareLoading: false,
  fetchedAllBenefitByCategory: [],
  benefitDocuments: []
}

const slice = createSlice({
  name: 'company-welfare',
  initialState,
  reducers: {
    selectBenefitDocument: (state, action: PayloadAction<SelectBenefitDocumentParams>) => {
      const findIndex = state.benefitDocuments.findIndex((benDoc) => benDoc.id === action.payload.benefitDocumentId)
      state.benefitDocuments[findIndex] = {
        ...state.benefitDocuments[findIndex],
        file: action.payload.file
      }
    },
    setIsEditWelfareRequest: (state, action: PayloadAction<boolean>) => {
      state.isEditWelfareRequest = action.payload
    },
    resetCompanyWelfareState: () => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(CompanyWelfareService.getAllBenefitByCategoryAsyncThunk.pending, (state) => {
        state.isGetAllBenefitByCategoryLoading = true
      })
      .addCase(CompanyWelfareService.getAllBenefitByCategoryAsyncThunk.rejected, (state) => {
        state.isGetAllBenefitByCategoryLoading = false
      })
      .addCase(CompanyWelfareService.getAllBenefitByCategoryAsyncThunk.fulfilled, (state, action) => {
        state.isGetAllBenefitByCategoryLoading = false
        if (action.payload) {
          state.fetchedAllBenefitByCategory = action.payload
        }
      })
      .addCase(CompanyWelfareService.getBenefitDetailByIdAsyncThunk.pending, (state) => {
        state.isGetBenefitDetailByIdLoading = true
      })
      .addCase(CompanyWelfareService.getBenefitDetailByIdAsyncThunk.rejected, (state) => {
        state.isGetBenefitDetailByIdLoading = false
      })
      .addCase(CompanyWelfareService.getBenefitDetailByIdAsyncThunk.fulfilled, (state, action) => {
        state.isGetBenefitDetailByIdLoading = false
        state.fetchedBenefitDetailById = action.payload
        state.benefitDocuments = action.payload.benefitDocumentMasters.map((benDoc) => ({
          id: benDoc.id,
          name: benDoc.name,
          isRequired: benDoc.isRequired,
          file: null,
          fileUrl: ''
        }))
      })
      .addCase(CompanyWelfareService.getHistoryUsedPointAsyncThunk.pending, (state) => {
        state.isGetHistoryUsedPointLoading = true
      })
      .addCase(CompanyWelfareService.getHistoryUsedPointAsyncThunk.rejected, (state) => {
        state.isGetHistoryUsedPointLoading = false
      })
      .addCase(CompanyWelfareService.getHistoryUsedPointAsyncThunk.fulfilled, (state, action) => {
        state.isGetHistoryUsedPointLoading = false
        state.fetchedHistoryUsedPoint = action.payload
      })
      .addCase(CompanyWelfareService.getRequestWelfareByHistoryIdAsyncThunk.pending, (state) => {
        state.isGetRequestWelfareByHistoryIdLoading = true
      })
      .addCase(CompanyWelfareService.getRequestWelfareByHistoryIdAsyncThunk.rejected, (state) => {
        state.isGetRequestWelfareByHistoryIdLoading = false
      })
      .addCase(CompanyWelfareService.getRequestWelfareByHistoryIdAsyncThunk.fulfilled, (state, action) => {
        state.isGetRequestWelfareByHistoryIdLoading = false
        state.fetchedRequestWelfareHistoryById = action.payload
        state.benefitDocuments = action.payload.benefitDocumentMasters.map((benDoc) => {
          const currentDocument = action.payload?.requestWelfareDetail.documents.find(
            (document) => document.benefitDocumentMasterId === benDoc.id
          )
          return {
            fileUrl: currentDocument ? currentDocument.fileUrl : '',
            id: benDoc.id,
            name: benDoc.name,
            isRequired: benDoc.isRequired,
            file: null
          }
        })
      })
      .addCase(CompanyWelfareService.createRequestWelfareAsyncThunk.pending, (state) => {
        state.isCreateRequestWelfareLoading = true
      })
      .addCase(CompanyWelfareService.createRequestWelfareAsyncThunk.rejected, (state) => {
        state.isCreateRequestWelfareLoading = false
      })
      .addCase(CompanyWelfareService.createRequestWelfareAsyncThunk.fulfilled, (state) => {
        state.isCreateRequestWelfareLoading = false
      })
      .addCase(CompanyWelfareService.updateRequestWelfareDetailByIdAsyncThunk.pending, (state) => {
        state.isUpdateRequestWelfareDetailByIdLoading = true
      })
      .addCase(CompanyWelfareService.updateRequestWelfareDetailByIdAsyncThunk.rejected, (state) => {
        state.isUpdateRequestWelfareDetailByIdLoading = false
      })
      .addCase(CompanyWelfareService.updateRequestWelfareDetailByIdAsyncThunk.fulfilled, (state) => {
        state.isUpdateRequestWelfareDetailByIdLoading = false
      })
      .addCase(CompanyWelfareService.cancelClaimWelfareRequestAsyncThunk.pending, (state) => {
        state.isCancelRequestWelfareLoading = true
      })
      .addCase(CompanyWelfareService.cancelClaimWelfareRequestAsyncThunk.rejected, (state) => {
        state.isCancelRequestWelfareLoading = false
      })
      .addCase(CompanyWelfareService.cancelClaimWelfareRequestAsyncThunk.fulfilled, (state) => {
        state.isCancelRequestWelfareLoading = false
      })
  }
})

export const { selectBenefitDocument, resetCompanyWelfareState, setIsEditWelfareRequest } = slice.actions
export default slice.reducer
