import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'

import auth from '../features/auth/stores/slice'
import claim from '../features/claim/stores/slice'
import companyWelfare from '../features/company-welfare/stores/slice'
import home from '../features/home/stores/slice'
import medical from '../features/medical/stores/slice'
import currentUser from './current-user/slice'
import visible from './visible/slice'

export const createStore = () =>
  configureStore({
    reducer: { auth, currentUser, visible, home, companyWelfare, claim, medical },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware()
  })

export const store = createStore()

export type AppDispatch = typeof store.dispatch
export const useAppDispatch: () => AppDispatch = useDispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>
